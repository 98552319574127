import React from 'react'
import "./style.scss"
import richTextRenderer from '../../utils/richTextRenderer'
import { graphql } from 'gatsby';
import Footer from '../../components/Footer';

const TermsOfService = ({ data }) => {

    const {
        allContentfulTermsOfService: { nodes: description },
    } = data

    const { description: mainDescription } = description[0];
    return (
        <div className='term-of-service-container'>
            <div className='row'>
                <h2 className='header-centre'>Terms & Condition</h2>
            </div>
            <div className='term-body mx-auto'>
                <p className='marginized-page '>{richTextRenderer(JSON.parse(mainDescription?.raw))}</p>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    )
}

export const query = graphql`
  query {
    allContentfulTermsOfService {
      nodes {
        id
        name
        description {
          raw
        }
      }
    }
  }
`

export default TermsOfService